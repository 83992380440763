@import '../variables';
.fullScreen {
    width: 100vw;
    height: 100vh;
    margin: 0px;
    padding: 0px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    @media (max-width: $screen-sm) {
        
    }

    .login-button {
        height: 3.5rem;
        margin: 2rem 0px;
        font-size: 1rem;
        &.otp-submit{
            margin-bottom: 0rem !important;
        }
    }
}