@import '../variables';
.input-container {
    display: flex;
    flex-direction: column;
    margin: 2em 0px;
    .form-label {
        margin-bottom: 5px;
    }
    input {
        width: 100%;
        height: 2.5em;
    }
}

.otp-input-boxes {
    input {
        width: 4.938rem !important;
        height: 4.438rem;
        border-radius: 5px;
        background-color: #FFF3F3;
        border: none;
        font-size: 1.5rem;
        text-align: center;
        color:#C93733;
        @media (max-width: $screen-sm) {
            width: 3.5rem !important;
            height: 3.5rem;
            font-size: .9rem;
        }
    }
    max-width: 23.625rem;
    @media (max-width: $screen-sm) {
        max-width: 15.625rem;
    }
}