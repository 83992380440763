.side-bar{
    width: 25.25rem;
    position: fixed;
    height: 100vh !important;
    padding: 1.563rem;
    .side-bar-menu{
        background: linear-gradient(180deg, #C93733 0%, #631B19 100%);
        border-radius: 1.875rem;
        height: 100%;
        width: 100%;
        padding: 5.488rem 0.938rem;
        ul.navbar{
            overflow: auto;
            height: 100%;
            list-style-type: none;
            li.nav-item{
                margin-bottom: .25rem;
                a.nav-link{
                    text-decoration: none;
                    display: block;
                    border-radius: 5px  !important;
                    width: 100% !important;
                }
                span{
                    font-size: 1.25rem !important;
                }
            }
              
            &::-webkit-scrollbar {
                width: 7px;
            }
              
            /* Track */
            &::-webkit-scrollbar-track {
                background-color: #631B19;
                border-radius: 10px;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #C93733; 
                border-radius: 10px;
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #911c18; 
            }
        }
    }
    .logout-btn{
        color: white;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 34.5px;
        position: absolute;
        bottom: 2.5rem;
        span{
            margin-left: 0.9rem;
        }
    }
    @media (max-width: $screen-xl) {
        width: 19.5rem;
        padding: .9563rem;
        .side-bar-menu{
            padding: 2.688rem 0.838rem;
            ul.navbar{
                li.nav-item{
                    margin-bottom: .15rem;
                    span{
                        font-size: .875rem !important;
                    }
                }
            }
        }
        .logout-btn{
            bottom: 1.5rem;
        }
    }
    @media (max-width: $screen-sm) {
        display: none;
        z-index: 999;
        right: 0;
        padding: 0;
        .side-bar-menu{
            border-radius: 0 !important;
        }
    }
    @media (max-width: $screen-xs) {
        max-width: 20.5rem;
        width: 100%;
    }

    .menu-close{
        display: none;
        position: absolute;
        color: #fff;
        right: 0px;
        @media (max-width: $screen-sm) {
            display: block;
        }
    }
}
html[data-side-bar-sm="open"]{
    @media (max-width: $screen-sm) {
        .side-bar{
            display: block;
        }
    }
}