@import '../../variables';
.login-form-container {
    width: 44%;
    @media (max-width:  850px) {
        width: 50%;
    }
    @media (max-width:  1080px) {
        width: 45%;
    }
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
    background-color: white;
    @media (max-width:  $screen-sm) {
        // position: absolute;
        justify-content: flex-start;
        margin-top: 40px;
        width: 100%;
        height: 100%;
        // align-self: center;
        // justify-self: center;
        // left: 50%;
        // transform: translateX(-50%); 
        
    }
    form {
        width: 100%;
        box-sizing: border-box;
        padding: 1em 20%;
        div {
            width: 100%;
        }
        @media (max-width:  $screen-sm) {
            padding: 1em 10% 0em 10%;
        }
        @media (max-width:  $screen-xs) {
            padding: 1em 5% 0em 5%;
        }
    }
    .mobile-view-accordions{
        display: none;
        @media (max-width:  $screen-sm) {
            display: block;
            padding: 0em 10% 1em 10%;
        }
        @media (max-width:  $screen-xs) {
            padding: 0em 5% 1em 5%;
        }
    }
    // h4.helper-text-heading,
    // span.helper-text {
    //     // display: none;
    //     @media (max-width :$screen-sm) {
    //         display: block;
    //         width: 60%;
    //         text-align: center;
    //         align-self: center;
    //     }
    //     @media (max-width :$screen-xs) {    
    //         width: 85%;
    //     }
    // }
    //to be removed
    .otp-main-heading{
        font-size: 3rem !important;
        font-weight: 600 !important;
        line-height: 2.375rem !important;
        margin-bottom: 3.5rem !important;
        @media (max-width: $screen-sm) {
            font-size: 1.5rem !important;
            margin-bottom: 1.5rem !important;
        }
    }
    .otp-sub-heading{
        font-size: 1.875rem !important;
        font-weight: 500 !important;
        line-height: 2.375rem !important;
        margin-bottom: 1rem !important;
        @media (max-width: $screen-sm) {
            font-size: .95rem !important;
            margin-bottom: 0.5rem !important;
        }
    }
    .otp-email-text{
        font-size: .963rem !important;
        font-weight: 300 !important;
        line-height: 1.375rem !important;
        margin-bottom: 2rem !important;
        color: #888888 !important;
        @media (max-width: $screen-sm) {
            font-size: .65rem !important;
            margin-bottom: 1rem !important;
        }
    }
    .otp-resend-heading{
        margin-top: 2.188rem !important;
        font-size: 1.5rem !important;
        font-weight: 600 !important;
        line-height: 2.375rem !important;
        color: #555555;
        @media (max-width: $screen-sm) {
            font-size: .95rem !important;
            margin-top: 1rem !important;
        }
    }
    .otp-resend-timing{
        margin-top: 2.188rem !important;
        font-size: 1.5rem !important;
        font-weight: 400 !important;
        line-height: 2.375rem !important;
        color: #555555; 
        @media (max-width: $screen-sm) {
            font-size: .95rem !important;
        }
    }
}

.logo {
    width: 40%;
    height: auto;
    max-width: 20em;
    margin: 1em 0px;
}