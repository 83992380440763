.top-bar{
    height: 6.75rem;
    border-bottom: 0.5px solid #A3A3A3;
    padding-top: 2.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .menu-icon{
        padding: 0 !important;
        height: fit-content;
        width: fit-content;
        display: none;
        @media (max-width: $screen-sm) { 
            display: block;
        }
    }
    @media (max-width: 1300px) { 
        padding-top: 1.5rem;
        height: 4.25rem;
        margin-bottom: .55rem;
        img{
            width: 165.60px;
            height: 24.80px;
        }
    }
    @media (max-width: $screen-sm) { 
        height: 5.5rem;
        padding-top: 2rem;
        img{
            width: 145.60px;
            height: 23.80px;
        }
    }
}
.top-bar,
.page-content{
    margin-left: 25.25rem;
    margin-right: 1.875rem;
    @media (max-width: $screen-xl) {
        margin-left: 19.5rem;
        margin-right: 1.275rem;
    }
    @media (max-width: $screen-sm) {
        margin-left: 1.875rem;
        margin-right: 1.875rem;
    }
}

.voting-screen-fixed{
    height: calc(100vh - 9.5rem) !important;
    @media (max-width: $screen-xl) {
        height: calc(100vh - 6.65rem) !important;
    }
    @media (max-width: $screen-lg) {
        height: calc(100vh - 7rem) !important;
    }
}
.voting-screen-no-fixed{
    margin-bottom: 2rem;
}