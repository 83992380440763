@import '../../variables';
.welcomeScreenContainer {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // background: linear-gradient(180deg, #C93733 0%, #822421 26.5%, #631B19 100%);
    // background: #f8f8f8;
    // background: radial-gradient(50% 50% at 50% 50%, rgba(151, 109, 108, 0) 0%, rgba(167, 121, 119, 0) 41%, rgba(202, 146, 145, 0) 76%, rgba(216, 156, 154, 0.0355555) 87.17%, rgba(233, 168, 166, 0.1) 100%);
    background-color: #e9a8a61a;
    //#ffb6b412
    //#a779772e
    //#ca929136
    //#e9a8a61a
    position: relative;
    z-index: 1;
    @media (max-width: 768px) {
        width: 100%
    }
        .text-container {
            height: 95%;
            display: flex;
            flex-direction: column;
            justify-content: end;
            padding-left: 5em;
            color: white;
            @media (max-width: 1440px) {
                padding-left: 3em;
            }
            @media (max-width: 768px) {
                display: none;
            }
        }
        .heading {
            font-size: 4em;
            letter-spacing: 2px;
            margin: 0px;
            @media (max-width: 1440px) {
                font-size: 3em;
            }
        }
        .helper-text {
            margin: 0;
            font-weight: 100;
            font-size: 1.25rem;
            line-height: 1.334;
            letter-spacing: 1px;
            max-width: 16em;
            @media (max-width: 1440px) {
                font-size: 1rem;
            }
        }

    // NEW CSS
    padding: 150px 75px 0px 75px;
    width: 56%;
    @media (max-width:  $screen-xl) {
        padding: 50px 45px 0px 45px;
    }
    @media (max-width:  850px) {
        width: 50%;
    }
    @media (max-width:  1080px) {
        width: 55%;
    }
    @media (max-width:  $screen-sm) {
        display: none;
    }

    .accordion-container{
        height: 100%;

        overflow: auto; /* Allows scrolling */
        scrollbar-width: none; /* Hides scrollbar in Firefox */
    }
    .accordion-container::-webkit-scrollbar {
        display: none; /* Hides scrollbar in Chrome, Safari */
    }

    .auth-accordions{
        margin-bottom: 13px;
    }
}

.trending-up-icon {
    height: auto;
    max-width: 12em;
    margin: 2em 0em 2px 4em;
    position: absolute;
    @media (max-width: 768px) {
        margin: 0px;
        max-width: 10em;
    }
}

.trending-up-bottom-icon {
    @extend .trending-up-icon;
    right: 2em;
    max-width: 14em;
    bottom: 2em;
    @media (max-width: 768px) {
        right: 0em;
        bottom: 0em;
    }
}

.brain-icon {
    height: auto;
    max-width: 8em;
    position: absolute;
    top: 30%;
    right: 3em;
    @media (max-width: 768px) {
        right: .2em;
    }
}