@import 'variables';
.text-muted{
    color: #636363 !important;
}

//for now
.auth-pass-inputgroup{
    position: relative;
    width: fit-content;
    .password-addon{
        position: absolute;
        right: 0;
        height: 100%;
    }
}
