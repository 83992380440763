/*CSS styling for components */
@import './components/login/welcome-screen.style.scss';
@import './components/login/login-form.style.scss';
@import './components/form-field.style.scss';
@import './components/layouts/header.scss';
@import './components/layouts/side-bar';
/* CSS styling for pages */
@import './pages/login.style.scss';
@import './../font-family/poppins.css';
@import './template';

body, #root {
    width: 100vw;
    height: 100vh;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif !important;
    overflow-x: hidden;
}
p, h1 ,h2 ,h3 ,h4 ,h5 ,h6 , span, label, input {
    font-family: 'Poppins', sans-serif !important;
    outline: none;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif !important;
}
.react-modal-select__control--is-disabled {
    border: none !important;
    background-color: #C9373310 !important;
}

.nomination-detail-label {
    font-size: 20px !important;
    line-height: 24px !important;
    font-weight: 500 !important;
    color: #000000 !important;
    margin-bottom: .75rem !important;
    @media (max-width: 1300px) {
        font-size: 16px !important;
        line-height: 20px !important;
        margin-bottom: 0rem !important;
    }
    @media (max-width: $screen-xs) {
        font-size: 14px !important;
        line-height: 18px !important;
        margin-bottom: 0.5rem !important;
    }
}

.nomination-form-label {
    font-size: 14px !important;
    color: #C93733  !important;
    margin-bottom: 0px !important;
}

.custom-readonly{
    background-color: #FAFAFA;
    color: #838383;
}

.quill{
    .ql-container {
        min-height: 15rem;
        max-height: 30rem;
    }
    .ql-editor {
        min-height: 15rem; /* Adjusts the inner editable area */
        max-height: 30rem;
        overflow: auto;
    }
}
#react-quill-custom-toolbar.show-placeholder{
    padding-right: 165px !important;
}
.react-quill-placeholder-dropdown{
    position: absolute;
    width: 150px;
    right: 8px;
    top: 10px;
    display: block !important;
}
span.react-quill-placeholder-dropdown{
    display: none !important;
}